import store from './store';

const Authorization = {
  /**
   * Installer Authorization plugin
   * @param Vue
   * @param options
   */
  install(Vue, options) {
    /**
     * Authorization functions
     * @type {{title: (function(*))}}
     */
    Vue.prototype.$authorization = {
      /**
       * Verify Authorization
       * @param scope
       * @returns {boolean}
       */
      can(scope) {
        if (store.state.auth.token.scopes) {
          return store.state.auth.token.scopes.includes(scope);
        }

        return false;
      }
    };
  }
};

export default Authorization;
