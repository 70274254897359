<template>
	<!-- BEGIN: Header -->
	<div
		id="m_header"
		class="m-grid__item m-header"
		m-minimize="minimize"
		m-minimize-mobile="minimize"
		m-minimize-offset="200"
		m-minimize-mobile-offset="200"
	>
		<div class="m-container m-container--fluid m-container--full-height">
			<div class="m-stack m-stack--ver m-stack--desktop m-header__wrapper">
				<!-- BEGIN: Brand -->
				<div class="m-stack__item m-brand m-brand--mobile">
					<div class="m-stack m-stack--ver m-stack--general">
						<div class="m-stack__item m-stack__item--middle m-brand__logo">
							<router-link :to="{name: 'home'}" class="m-brand__logo-wrapper">
								<img alt src="@/assets/img/logo/claro-logo.png" height="50px" />
							</router-link>
						</div>
						<div class="m-stack__item m-stack__item--middle m-brand__tools">
							<!-- BEGIN: Responsive Header Menu Toggler -->
							<a
								id="m_aside_header_menu_mobile_toggle"
								href="javascript:;"
								class="m-brand__icon m-brand__toggler"
							>
								<span></span>
							</a>
							<!-- END -->
							<!-- BEGIN: Topbar Toggler -->
							<a id="m_aside_header_topbar_mobile_toggle" href="javascript:;" class="m-brand__icon">
								<i class="flaticon-more"></i>
							</a>
							<!-- BEGIN: Topbar Toggler -->
						</div>
					</div>
				</div>
				<div
					class="m-stack__item m-stack__item--middle m-stack__item--left m-header-head"
					id="m_header_nav"
				>
					<div class="m-stack m-stack--ver m-stack--desktop">
						<div class="m-stack__item m-stack__item--fluid">
							<!-- BEGIN: Horizontal Menu -->
							<div
								id="m_header_menu"
								class="pl-0 m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark"
							>
								<ul class="m-menu__nav m-menu__nav--submenu-arrow">
									<li
										v-for="(item, index) in menu"
										:key="index"
										v-if="$authorization.can(item.scope)"
										class="m-menu__item m-menu__item--submenu m-menu__item--rel"
										@mouseover="openMenu(index)"
										@mouseleave="closeMenu(index)"
										m-menu-submenu-toggle="hover"
										aria-haspopup="true"
									>
										<router-link
											v-if="item.route"
											:to="{name: item.route}"
											class="m-menu__link m-menu__toggle"
										>
											<span class="m-menu__item-here"></span>
											<i :class="'m-menu__link-icon ' + item.icon"></i>
											<span class="m-menu__link-text">{{ item.title }}</span>
											<i class="m-menu__hor-arrow la la-angle-down" v-if="item.submenu.length > 0"></i>
											<i class="m-menu__ver-arrow la la-angle-right" v-if="item.submenu.length > 0"></i>
										</router-link>
										<a href="javascript:;" class="m-menu__link m-menu__toggle" v-else>
											<span class="m-menu__item-here"></span>
											<i :class="'m-menu__link-icon ' + item.icon"></i>
											<span class="m-menu__link-text">{{ item.title }}</span>
											<i class="m-menu__hor-arrow la la-angle-down"></i>
											<i class="m-menu__ver-arrow la la-angle-right"></i>
										</a>
										<div
											v-if="item.submenu.length > 0"
											class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left"
										>
											<span class="m-menu__arrow m-menu__arrow--adjust" style="left: 79.5px;"></span>
											<ul class="m-menu__subnav">
												<li
													v-for="(child, index) in item.submenu"
													v-if="$authorization.can('module-service')"
													:key="index"
													class="m-menu__item"
													aria-haspopup="true"
												>
													<router-link :to="{name: child.route}" class="m-menu__link">
														<span class="m-menu__link-title">
															<span class="m-menu__link-wrap">
																<span class="m-menu__link-text">{{ child.title }}</span>
															</span>
														</span>
													</router-link>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</div>
							<!-- END: Horizontal Menu -->
						</div>
					</div>
				</div>
				<div class="m-stack__item m-stack__item--middle m-stack__item--center">
					<!-- BEGIN: Brand -->
					<router-link :to="{name: 'home'}" :class="'m-brand m-brand--desktop'">
						<img alt src="@/assets/img/logo/claro-logo.png" height="50px" />
					</router-link>
					<!-- END: Brand -->
				</div>
				<div class="m-stack__item m-stack__item--right">
					<!-- BEGIN: Topbar -->
					<div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general">
						<div v-if="$route.name !== 'register'" class="m-stack__item m-topbar__nav-wrapper">
							<ul class="m-topbar__nav m-nav m-nav--inline">
								<li
									class="m-nav__item m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
									m-dropdown-toggle="click"
								>
									<a href="#" class="m-nav__link m-dropdown__toggle">
										<span class="m-topbar__username m--hidden-mobile">{{ $store.state.auth.user.firstName }}</span>
										<span class="m-topbar__userpic">
											<img
												v-if="$store.state.auth.user.avatarUrl"
												:src="$store.state.auth.user.avatarUrl"
												class="m--img-rounded m--marginless m--img-centered"
											/>
											<img
												v-else
												src="@/assets/img/user-placeholder.png"
												class="m--img-rounded m--marginless m--img-centered"
											/>
										</span>
										<span class="m-nav__link-icon m-topbar__usericon m--hide">
											<span class="m-nav__link-icon-wrapper">
												<i class="flaticon-user-ok"></i>
											</span>
										</span>
									</a>
									<div class="m-dropdown__wrapper">
										<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
										<div class="m-dropdown__inner">
											<div class="m-dropdown__body">
												<div class="m-dropdown__content">
													<ul class="m-nav m-nav--skin-light">
														<li class="m-nav__item">
															<router-link :to="{ name: 'profile' }" class="m-nav__link">
																<i class="m-nav__link-icon flaticon-cogwheel-1"></i>
																<span class="m-nav__link-text">Configurações</span>
															</router-link>
														</li>
														<li class="m-nav__item">
															<a href="javascript:;" @click="logout" class="m-nav__link">
																<i class="m-nav__link-icon flaticon-logout"></i>
																<span class="m-nav__link-text">Sair</span>
															</a>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<!-- END: Topbar -->
				</div>
			</div>
		</div>
	</div>
	<!-- END: Header -->
</template>

<script>
	export default {
		name: "top-header",
		data() {
			return {
				firstName: this.$store.state.auth.user.firstName,
				lastName: this.$store.state.auth.user.lastName,
				menu: [
					{
						title: "",
						icon: this.showHomePartnerIcon() ? "flaticon-home-1" : "",
						route: "home.partner",
						submenu: [],
						scope: "module-service"
					},
					{
						title: "Serviços",
						icon: "flaticon-app",
						route: "services",
						submenu: [],
						scope: "module-service"
					},
					// {
					// 	title: "Campanhas",
					// 	icon: "flaticon-calendar-1",
					// 	route: "campaign",
					// 	scope: "module-campaign",
					// 	submenu: [
					// 		{
					// 			title: "Gestão de Campanhas",
					// 			icon: "",
					// 			scope: "campaigns",
					// 			route: "campaign"
					// 		},
					// 		{
					// 			title: "Cronogramas",
					// 			icon: "campaign-schedules",
					// 			scope: "campaign-schedules",
					// 			route: "campaign.schedules"
					// 		}
					// 	]
					// },
					{
						title: "Provedores",
						icon: "flaticon-suitcase",
						route: "partners",
						submenu: [],
						scope: "module-partners"
					},

					{
						title: "Campanhas",
						icon: "flaticon-calendar-1",
						route: "service-campaigns",
						submenu: [],
						scope: "campaign-list-all"
					}
				]
			};
		},
		methods: {
			logout() {
				this.$store
					.dispatch("logout", this.$store.state.auth.user.id)
					.then(response => {
						this.$router.push({ name: "login" });
					})
					.catch(error => {
						console.log(error);
					});
			},
			openMenu(index) {
				$("body .m-menu__item--submenu")
					.eq(index)
					.addClass("m-menu__item--open-dropdown m-menu__item--hover");
			},
			closeMenu(index) {
				$("body .m-menu__item--submenu")
					.eq(index)
					.removeClass("m-menu__item--open-dropdown m-menu__item--hover");
			},
			showHomePartnerIcon() {
				switch (this.$store.state.auth.user.company_profile_id) {
					case 3:
					case 4:
					case 5:
					case 9:
						return true;
				}

				return false;
			}
		}
	};
</script>
