export default  {
  state: {
    editing: {
      channel: null,
      channelForm: null,
      campaign: {},
      media: {},
      files: {},
      offer: {},
    },
    media: {},
    s3Path: process.env.VUE_APP_S3_PATH,
  },
  mutations: {
    setChannelForm(state, value) {
      state.editing.channelForm = value;
    },
    setCampaign(state, campaign) {
      state.editing.campaign = campaign;
    },
    setChannel(state, channel) {
      state.editing.channel = channel;
    },
    setMedia(state, media) {
      state.media = media;
    },
    setEditingMedia(state, media) {
      state.editing.media = media;
    },
    setOffer(state, offer) {
      state.editing.offer = offer;
    },
    appendFiles(state, value) {
      state.editing.files = value;
    },
    clearFiles(state) {
      state.editing.files = {};
    },
    setMenuOptions(state, menu_options) {
      state.editing.media.menu_options = menu_options;
    }
  }
}
