// Fonts
import 'linearicons/dist/web-font/style.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import 'toastr/build/toastr.min.css';
import './assets/vendors/fontawesome5/css/all.min.css';
import './assets/vendors/metronic/css/styles.css';
import './assets/vendors/flaticon/css/flaticon.css';
import './assets/vendors/line-awesome/css/line-awesome.min.css';

// Styles
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './assets/scss/theme/style.scss';

window.swal = require('sweetalert2');
window.toastr = require('toastr');
