export default [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: 'Perfil',
      layout: 'default-layout',
      auth: true
    },
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/invite',
    name: 'invite',
    meta: {
      title: 'Invite',
      layout: 'default-layout',
      auth: true
    },
    component: () => import('../views/Invite.vue')
  },
  {
    path: '/invite/register/:email',
    name: 'invite.register',
    meta: {
      title: 'Convite',
      layout: 'invite-layout',
      auth: false
    },
    component: () => import('../views/InviteRegister.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "acl" */ '../views/Users.vue'),
    meta: {
      layout: 'default-layout',
      title: 'Users',
      auth: true
    }
  },
  {
    path: '/acl',
    name: 'acl',
    component: () => import(/* webpackChunkName: "acl" */ '../views/ACL.vue'),
    meta: {
      layout: 'default-layout',
      title: 'Acl',
      auth: true
    }
  },
  {
    path: '/acl/edit/:id',
    name: 'acl.edit',
    component: () => import(/* webpackChunkName: "acl" */ '../views/ACLEdit.vue'),
    meta: {
      layout: 'default-layout',
      title: 'Acl',
      auth: true
    }
  }
];
