<template>
    <div class="m-grid m-grid--hor m-grid--root m-page" id="login-layout">

        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin">
            <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
                <div class="m-stack m-stack--hor m-stack--desktop">
                    <div class="m-stack__item m-stack__item--fluid">
                        <div class="m-login__wrapper">
                            <div class="m-login__logo">
                                <a href="#">
                                    <img src="@/assets/img/logo/claro-logo.png" class="logo-left">
                                </a>
                            </div>
                            <router-view/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="login-background m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content m-grid-item--center">
                <div class="m-grid__item">
                    <div class="text-center">
                        <img src="@/assets/img/logo/uotz-white-logo.png" height="110px" style="margin-bottom: 30px">
                        <p class="m-login__msg">
                            Plataforma digital de gestão de SVA's da Claro.<br><br>
                            Powered by <a href="https://uotz.com.br" class="text-white m--font-boldest">UOTZ</a>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: 'login-layout',
  mounted() {
    $('body').addClass('m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default')
  }
}
</script>

<style lang="scss">
    .login-background {
        background: #d7321e;
        /*background-image: url('../../assets/img/background/1.png');*/
    }

    .logo-left {
        width: 150px;
        height: auto;
    }
</style>