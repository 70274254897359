import Vue from 'vue';
import Moduler from './moduler';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history'
});

//Add module routes
if (Object.keys(Moduler.modules).length > 0) {
  for (let module in Moduler.modules) {
    if (Moduler.modules.hasOwnProperty(module)) {
      //Check if prop routes exists
      if (Moduler.modules[module].routes) {
        //Add to routes
        router.addRoutes(Moduler.modules[module].routes);
      }
    }
  }
}

router.beforeEach((to, from, next) => {
  // Page Title
  if (to.meta.title) {
    const titlePrefix = process.env.VUE_APP_NAME;
    window.document.title = `${to.meta.title} | ${titlePrefix}`;
  }
  //AuthMiddleware
  if (to.meta.auth === true) {
    if (!localStorage.token || !localStorage.user) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
