export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth-login" */ '../views/Login.vue'),
    meta: {
      layout: 'login-layout',
      title: 'Login',
      guest: true
    }
  },
  {
    path: '/reset/password/:rt',
    name: 'reset.password',
    component: () => import(/* webpackChunkName: "auth-login" */ '../views/ResetPassword.vue'),
    meta: {
      layout: 'login-layout',
      title: 'Login',
      guest: true
    }
  }
];
