export default {
  state: {
    service: {},
  },
  mutations: {
    /**
     * Set service
     * @param state
     * @param service
     */
    setService(state, service) {
      state.service = service;
    },
  },
  actions: {
    /**
     *
     * @param context
     * @param service
     */
    setService(context, service) {
      context.commit('setService', service);
    },
  },
};
