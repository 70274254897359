const Helper = {
  /**
   * Installer Helper plugin
   * @param Vue
   * @param options
   */
  install(Vue, options) {
    /**
     * Helper functions
     * @type {{title: (function(*))}}
     */
    Vue.prototype.$helper = {
      /**
       * Change browser window title
       * @param title
       */
      title(title) {
        window.document.title = title;
      },

      /**
       * Convert date to PT-BR
       * @param date
       * @param full
       * @returns {string}
       */
      convertDatePtBr(date, full = true) {
        if (date) {
          date = date.split(' ');
          if (full) {
            return `${date[0]
              .split('-')
              .reverse()
              .join('/')} ${date[1]}`.toString();
          }
          return `${date[0]
            .split('-')
            .reverse()
            .join('/')}`.toString();
        }

        return null;
      },

      /**
       * Convert date to US
       * @param date
       * @returns {string}
       */
      convertDateUS(date, full = false) {
        if (date) {
          let full_hour = '';
          if (full) {
            let date = new Date();
            let hour = date.getHours();
            hour = hour < 10 ? `0${hour}` : hour;
            let min = date.getMinutes();
            min = min < 10 ? `0${min}` : min;
            let sec = date.getSeconds();
            sec = sec < 10 ? `0${sec}` : sec;

            full_hour = `${hour}:${min}:${sec}`;
          }

          date = date.split(' ');
          return `${date[0]
            .split('/')
            .reverse()
            .join('-')} ${full_hour}`.toString();
        }

        return null;
      },

      formRequestErrorsMessage(error, errorsInfo = 'Dados Inválidos. Verifique os dados informados e tente novamente.') {
        let response = error.response;
        let data = response.data;
        let errors = data.errors;

        if (response.status == 422 && errors && data.message == 'The given data was invalid.') {
          errorsInfo = '';
          let keys = Object.keys(errors);

          if (keys) {
            keys.forEach((key) => {
              if (errors[key]) {
                errors[key].forEach((info) => {
                  errorsInfo += `${info}<br/>`;
                });
              }
            });
          }
        }

        return errorsInfo;
      }
    };
  }
};

export default Helper;
