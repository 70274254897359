import Vue from 'vue';
import VueQuillEditor from 'vue-quill-editor';
import { VueEditor } from 'vue2-editor';

// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueQuillEditor);
Vue.component('vue-editor', VueEditor);
