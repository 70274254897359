export default {
  state: {
    loading: true,
  },
  mutations: {
    setLoadingACL: (state, loading) => {
      state.loading = loading;
    }
  },
  actions: {
    setLoadingACL(context, loading) {
      context.commit('setLoadingACL', loading);
    },
  }
};
