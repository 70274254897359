export default [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Serviços",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Services.vue")
  },
  {
    path: "/services",
    name: "services",
    meta: {
      title: "Serviços",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Services.vue")
  },
  {
    path: "/service/basic-info/:id?",
    name: "service.basic-info",
    meta: {
      title: "Informações Básicas",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/BasicInfo.vue")
  },
  {
    path: "/service/offers/:id?",
    name: "service.offers",
    meta: {
      title: "Ofertas",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Offers.vue")
  },
  {
    path: "/service/keywords/:id?",
    name: "service.keywords",
    props: true,
    meta: {
      title: "Keywords",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Keywords.vue")
  },
  {
    path: "/service/messages/:id?",
    name: "service.messages",
    meta: {
      title: "Mensagens",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Messages.vue")
  },
  {
    path: "/service/sharecodes/:id?",
    name: "service.sharecodes",
    meta: {
      title: "Sharecodes",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Sharecodes.vue")
  },
  {
    path: "/service/activation/:id?",
    name: "service.activation",
    meta: {
      title: "Ativação",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Activation.vue")
  },
  {
    path: "/service/usage/:id?",
    name: "service.usage",
    meta: {
      title: "Uso",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Usage.vue")
  },
  {
    path: "/service/cancellation/:id?",
    name: "service.cancellation",
    meta: {
      title: "Cancelamento",
      layout: "default-layout",
      auth: true
    },
    component: () => import("../views/Cancellation.vue")
  },
  {
    path: "/service/storage/:id",
    name: "service.storage",
    meta: {
      title: "Documentos",
      layout: "default-layout",
      guest: true
    },
    component: () => import("../views/Storage.vue")
  },
  {
    path: "/service/history/:id",
    name: "service.history",
    meta: {
      title: "Histórico",
      layout: "default-layout",
      guest: true
    },
    component: () => import("../views/History.vue")
  },

  {
    path: "/service/campaign/:id",
    name: "service.campaign",
    meta: {
      title: "Campanha",
      layout: "default-layout",
      guest: true
    },
    component: () => import("../views/Campaign.vue")
  }
];
