import AuthModule from './modules/auth';
import CampaignModule from './modules/campaign';
import ServiceModule from './modules/service';
import ServiceCampaignModule from './modules/service-campaign';
import PartnerModule from './modules/partner';
import ConfigurationModule from './modules/configuration';

export default {
  modules: {
    auth: AuthModule,
    // campaign: CampaignModule,
    service: ServiceModule,
    serviceCampaign: ServiceCampaignModule,
    partner: PartnerModule,
    //acl: AclModule,
    configuration: ConfigurationModule
  }
};
