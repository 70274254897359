<template>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <top-header/>
        <!-- begin::Body -->
        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--hor-desktop m-grid--desktop m-body">
            <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-container--full-height">
                <div class="m-grid__item m-grid__item--fluid m-wrapper">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import TopHeader from './components/TopHeader';
  export default {
    name: 'default-layout',
    components: {
      'top-header': TopHeader
    },
    created() {
      $('body').addClass('m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--offcanvas-default m-aside-left--enabled m-aside-left--fixed m-aside-left--skin-dark m-aside--offcanvas-default');
    }
  }
</script>