<template>
  <div class="m-grid m-grid--hor m-grid--root m-page">
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-container m-container--responsive m-container--xxl m-container--full-height">
      <div class="m-grid__item m-grid__item--fluid m-wrapper">
          <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "layout-invite",
}
</script>