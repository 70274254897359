<template>
    <compnent :is="layout"></compnent>
</template>

<script>
import LoginLayout from './layouts/auth/Login.vue';
import DefaultLayout from './layouts/app/Default.vue';
import InviteLayout from './layouts/custom/Invite.vue';

export default {
  name: 'app',
  components: {
    LoginLayout,
    DefaultLayout,
    InviteLayout
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    }
  }
}
</script>
