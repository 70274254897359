export default [
  {
    path: '/service-campaigns/',
    name: 'service-campaigns',
    meta: {
      title: 'Campanhas',
      layout: 'default-layout',
      auth: true
    },
    component: () => import('../views/Campaigns.vue')
  },
];
