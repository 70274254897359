import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VeeValidate, { Validator } from 'vee-validate';
import ptBr from 'vee-validate/dist/locale/pt_BR';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import helper from './helper';
import authorization from './authorization';
import router from './router';
import store from './store';
import './theme';
import './plugins/quill';

window.axios = require('axios');

window.baseURL = process.env.VUE_APP_SGVAS_API;
window.axios.defaults.baseURL = process.env.VUE_APP_SGVAS_API;
window.axios.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${store.state.auth.token.accessToken}`
};

window.axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    if (
      401 === error.response.status ||
      (403 === error.response.status &&
        error.response.data.message === 'Invalid scope(s) provided.')
    ) {
      if (localStorage.token) {
        store.dispatch('revokeToken');
      }
      router.push({ name: 'login' });
      return;
    }
    return Promise.reject(error);
  }
);

Validator.localize('pt_BR', ptBr);

Vue.config.productionTip = false;

Vue.use(helper);
Vue.use(authorization);
Vue.use(BootstrapVue);
Vue.use(VeeValidate, {
  locale: 'pt_BR',
  dictionary: {
    pt_BR: ptBr
  }
});
Vue.use(VueTheMask);

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app');
