export default [
  {
    path: '/partners',
    name: 'partners',
    meta: {
      title: 'Parceiros',
      layout: 'default-layout',
      auth: true
    },
    component: () => import('../views/Partners.vue')
  },
  {
    path: '/home-partner/:id?',
    name: 'home.partner',
    meta: {
      title: 'HomePartner',
      layout: 'default-layout',
      auth: true
    },
    component: () => import('../views/HomePartner.vue')
  },
  {
    path: '/create-partner',
    name: 'create.partner',
    meta: {
      title: 'Cadastro de Parceiro',
      layout: 'default-layout',
      auth: true
    },
    component: () => import('../views/CreatePartners.vue')
  },
  {
    path: '/edit-partner/:id',
    name: 'edit.partner',
    meta: {
      title: 'Editar de Parceiro',
      layout: 'default-layout',
      auth: true
    },
    component: () => import('../views/EditPartner.vue')
  }
];
