export default {
  state: {
    lock: false,
    service: {}
  },
  mutations: {
    /**
     * Set service
     * @param state
     * @param service
     */
    setService(state, service) {
      state.service = service;
    },
    setShowKeywords(state, show) {
      state.showKeyword = show;
    },
    setLock(state, lock) {
      state.lock = lock;
    }
  },
  actions: {
    /**
     *
     * @param context
     * @param service
     */
    setService(context, service) {
      context.commit('setService', service);
    }
  }
};
