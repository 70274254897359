import Vue from 'vue'
import Vuex from 'vuex'
import Moduler from './moduler';

Vue.use(Vuex);

const storeModules = {};
//Check if has modules
if (Object.keys(Moduler.modules).length > 0) {
  //Each module
  for (let module in Moduler.modules) {
    if (Moduler.modules.hasOwnProperty(module)) {
      //Check if routes exists
      if (Moduler.modules[module].store) {
        const store = {};
        store[module] = Moduler.modules[module].store;
        //Add store from module
        Object.assign(storeModules, store);
      }
    }
  }
}

export default new Vuex.Store({
  modules: storeModules
})
