import swal from 'sweetalert2';

/**
 * Parse JSON from local storage
 * @param item
 * @param key
 * @returns {*}
 */
function getLocalStorageJSONData(item, key) {
  const data = JSON.parse(localStorage.getItem(item));
  if (data && data.hasOwnProperty(key)) {
    return data[key];
  }
  return null;
}

export default {
  state: {
    token: {
      accessToken: getLocalStorageJSONData('token', 'accessToken'),
      expiresIn: getLocalStorageJSONData('token', 'expiresIn'),
      refreshToken: getLocalStorageJSONData('token', 'refreshToken'),
      scopes: localStorage.getItem('scopes') ? localStorage.getItem('scopes').split(',') : null
    },
    user: {
      id: getLocalStorageJSONData('user', 'id'),
      avatarUrl: getLocalStorageJSONData('user', 'avatarUrl'),
      firstName: getLocalStorageJSONData('user', 'firstName'),
      lastName: getLocalStorageJSONData('user', 'lastName'),
      profile: getLocalStorageJSONData('user', 'profile'),
      company_profile_id: getLocalStorageJSONData('user', 'company_profile_id'),
      shortName: getLocalStorageJSONData('user', 'shortName'),
      email: getLocalStorageJSONData('user', 'email'),
      phone: getLocalStorageJSONData('user', 'phone'),
      company_id: getLocalStorageJSONData('user', 'company_id')
    }
  },
  mutations: {
    /**
     * Save accessToken as a cookie
     * @param state
     * @param token
     */
    setToken: (state, token) => {
      state.token.accessToken = token.access_token;
      state.token.expiresIn = token.expires_in;
      state.token.refreshToken = token.refresh_token;
      localStorage.setItem('token', JSON.stringify(state.token));
      window.axios.defaults.headers.common = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access_token}`
      };
    },

    /**
     * Save scopes as a cookie
     * @param state
     * @param scopes
     */
    setScopes: (state, scopes) => {
      state.token.scopes = scopes;
      localStorage.setItem('scopes', scopes);
    },

    /**
     * Save accessToken as a cookie
     * @param state
     */
    clearToken: (state) => {
      state.token.accessToken = null;
      state.token.expiresIn = null;
      state.token.refreshToken = null;
      state.token.scopes = null;
      localStorage.removeItem('token');
    },

    /**
     * Set user
     * @param state
     * @param user
     */
    setUser: (state, user) => {
      state.user.id = user.id;
      state.user.avatarUrl = user.avatar_url || '';
      state.user.firstName = user.first_name;
      state.user.lastName = user.last_name;
      state.user.profile = user.profile_id;
      state.user.company_profile_id = user.company_profile_id;
      state.user.shortName = user.short_name;
      state.user.email = user.email;
      state.user.phone = user.phone;
      state.user.company_id = user.company_id;
      localStorage.setItem('user', JSON.stringify(state.user));
    },

    /**
     * Clear user from state and local storage
     * @param state
     */
    clearUser: (state) => {
      state.user.id = null;
      state.user.avatarUrl = '';
      state.user.firstName = null;
      state.user.lastName = null;
      state.user.email = null;
      state.user.phone = null;
      state.user.initials = null;
      state.user.shortName = null;
      localStorage.removeItem('user');
    },

    /**
     * Clear user from state and local storage
     * @param state
     */
    clearScopes: (state) => {
      state.token.scopes = null;
      localStorage.removeItem('scopes');
    }
  },
  actions: {
    login(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('auth/login', credentials)
          .then((response) => {
            context.commit('setToken', JSON.parse(response.data.token));
            context.commit('setUser', response.data.user);
            context.commit('setScopes', response.data.scopes);
            resolve(response);
          })
          .catch((error) => {
            context.commit('clearToken');
            context.commit('clearUser');
            context.commit('clearScopes');
            reject(error);
          });
      });
    },
    logout(context, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`auth/logout/${id}`)
          .then((response) => {
            context.commit('clearToken');
            context.commit('clearUser');
            context.commit('clearScopes');
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resetPassword(context, email) {
      axios
        .post(`auth/mail/reset/password/${email}`)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    verifyRememberToken(context, rememberToken) {
      return new Promise((resolve, reject) => {
        axios
          .post(`auth/verify-remember-token/${rememberToken}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    changePassword(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`auth/change/password/${data.token}`, { password: data.password })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            swal('Ops!', 'Ocorreu um erro ao redefinir a senha', 'error');
            reject();
          });
      });
    }
  },
  getters: {
    authenticated(state) {
      return state.token !== null && state.token.accessToken !== null;
    }
  }
};
