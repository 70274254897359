export default {
  state: {
    loading: true,
  },

  getters: {
    loading: state => {
      return state.loading;
    }
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {},
};
